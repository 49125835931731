<template>
  <div class="pb-4" style="background-color: #fff" id="page-pendaftaran">
    <b-container fluid>
      <b-row style="padding-top: 15px">
        <b-col cols="12" md="12" lg="8" xl="8">
          <div
            style="
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: ;
            "
          >
            <h6
              style="font-weight: 700; color: #9c4098; font-size: 22px; margin-bottom: 0">
              Change Password
            </h6>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>
      <b-row class="m-0 p-0">
        <b-col cols="12">
          <label for="password_baru">Password Baru</label>
          <b-form-input
            id="password_baru"
            v-model="$v.dataForm.password_baru.$model"
            type="password"
            placeholder="Password Baru"
            :state="checkIfValid('password_baru')"
          ></b-form-input>
        </b-col>
        <b-col cols="6">
          <label for="password_lama">Password Lama</label>
          <b-form-input
            id="password_lama"
            v-model="$v.dataForm.password_lama.$model"
            type="password"
            placeholder="Password Lama"
            :state="checkIfValid('password_lama')"
          ></b-form-input>
        </b-col>
        <b-col cols="6">
          <label for="ulang_password_lama">Ulangi Password Baru</label>
          <b-form-input
            id="ulang_password_lama"
            v-model="$v.dataForm.ulang_password_lama.$model"
            type="password"
            placeholder="Password Baru"
            :state="checkIfValid('ulang_password_lama')"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row style="margin-top:20px" align-h="end">
        <b-col cols="auto">
          <b-button
            @click="changePassword()"
            variant="primary"
            :disabled="busy || !isValid"
            >Change
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";
export default {
  props: [
  ],
  data() {
    return {
      busy: false,
      dataForm: {
        password_baru: null,
        password_lama: null,
        ulang_password_lama: null,
      }
    }
  },
  computed: {
    isValid() {
      return !this.$v.dataForm.$invalid;
    },
    isDirty() {
      return this.$v.dataForm.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataForm: {
      password_baru: { required },
      password_lama: { required },
      ulang_password_lama: { required, sameAs: sameAs(function(){return this.dataForm.password_lama}) },
    },
  },
  watch: {
  },
  async mounted() {
    console.log(' vm.$store.state',  this.$store.state)
  },
  methods: {
    async changePassword(){
      const vm = this
      vm.busy = false
      try {
        console.log('get data')
        const res = await vm.$axios.post('/user/ubah_password', {
          ...vm.dataForm,
          id: vm.$store.state.login.id
        })
        console.log('/user/ubah_password', res)
        if(res.data.status == 200){
          vm.triggerAlert({ variant: "success", msg: 'Berhasil Update Password', showing: true });
          await new Promise(resolve => setTimeout(resolve, 100));
          vm.$store.commit('clear_data')
          vm.$store.commit('set_alert', {variant:'success', msg:'BERHASIL LOGOUT'})
          vm.$router.push({path:'/'})
        }else{
          vm.triggerAlert({ variant: "danger", msg: res.data.message, showing: true });
        }
        // admin_fosan
      } catch (error) {
        vm.triggerAlert({ variant: "danger", msg: 'Terjadi kesalahan system', showing: true });
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    checkIfValid(fieldName) {
      const field = this.$v.dataForm[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>